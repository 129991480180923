import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

const SendMoney = () => {
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [transferCompleted, setTransferCompleted] = useState(false);
  const [routingNumber, setRoutingNumber] = useState("");
  const [transferOption, setTransferOption] = useState("Local");
  const [country, setCountry] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  const sendOtp = async () => {
    setLoading(true);
    const token = localStorage.getItem("cathay-dashboard");

    try {
      const { data } = await axios.post(
        `https://app.cathaynational.com/api/transaction/initiate/${user?._id}`,
        {
          user,
          amount: +amount,
          description,
          email: user?.email,
          transactionId: new Date().getTime().toString(),
          transferOption,
          dateTime: new Date(),
          bankDetails: {
            accountName,
            bankName,
            accountNumber,
            accountType,
            routingNumber,
            country,
            swiftCode,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      if (data.message === "Verification email sent") {
        setLoading(false);
        setOtpModalOpen(true);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const checkOtp = async (e) => {
    e.preventDefault();

    setLoading(true);
    const token = localStorage.getItem("cathay-dashboard");

    try {
      await axios.post(
        `https://app.cathaynational.com/api/transaction/verify/${user?._id}`,
        { otp: +otp },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setOtpModalOpen(false);
      setTransferCompleted(true);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div id="main-wrapper">
      <Header />
      <div id="content" className="py-4">
        <div className="container">
          <div className="row">
            <Sidebar activePage="Send money" />
            {!user?.transferDisabled ? (
              <>
                {!otpModalOpen && !transferCompleted && (
                  <div className="col-lg-9">
                    <h3 className="text-5 font-weight-700 d-flex admin-heading">
                      Send Money
                    </h3>
                    <div className="bg-white">
                      <div className="row">
                        <div className="col-md-8 col-lg-8 mx-auto">
                          <div className="bg-light shadow-md rounded p-3 p-sm-4 mb-8 mt-8 form-box">
                            <form
                              id="form-send-money"
                              className="form"
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (+amount <= +user.balance) {
                                  sendOtp();
                                } else {
                                  toast.error(
                                    "Insufficient balance. Please top up your account balance."
                                  );
                                }
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="amount">Amount</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text currency-icon">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-control"
                                    id="amount"
                                    required
                                    value={amount}
                                    placeholder="10000"
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label htmlFor="transferOption">
                                  Transfer Option
                                </label>
                                <select
                                  id="transferOption"
                                  className="custom-select"
                                  required
                                  value={transferOption}
                                  onChange={(e) =>
                                    setTransferOption(e.target.value)
                                  }
                                >
                                  <option value="Local">Local</option>
                                  <option value="International">
                                    International
                                  </option>
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="accountName">
                                  Account Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="accountName"
                                  required
                                  placeholder="Enter recipient account name"
                                  value={accountName}
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="accountNumber">
                                  Account Number
                                </label>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="accountNumber"
                                  required
                                  placeholder="Enter recipient account number"
                                  value={accountNumber}
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="bankName">Bank Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bankName"
                                  required
                                  placeholder="Enter recipient bank name"
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="accountType">
                                  Account Type
                                </label>
                                <select
                                  id="accountType"
                                  className="custom-select"
                                  required
                                  value={accountType}
                                  onChange={(e) =>
                                    setAccountType(e.target.value)
                                  }
                                >
                                  <option value="">--</option>
                                  <option value="Savings account">
                                    Savings account
                                  </option>
                                  <option value="Current account">
                                    Current account
                                  </option>
                                  <option value="Checking account">
                                    Checking account
                                  </option>
                                  <option value="Fixed Deposit">
                                    Fixed Deposit
                                  </option>
                                  <option value="Non-resident account">
                                    Non-resident account
                                  </option>
                                  <option value="Online banking">
                                    Online banking
                                  </option>
                                  <option value="Domiciliary account">
                                    Domiciliary account
                                  </option>
                                  <option value="Joint account">
                                    Joint account
                                  </option>
                                </select>
                              </div>
                              {transferOption === "International" && (
                                <div className="form-group">
                                  <label htmlFor="country">Country</label>
                                  <select
                                    id="country"
                                    className="custom-select"
                                    required
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                  >
                                    <option value="">-</option>
                                    <option value="Afganistan">
                                      Afghanistan
                                    </option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">
                                      American Samoa
                                    </option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua &amp; Barbuda">
                                      Antigua &amp; Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">
                                      Azerbaijan
                                    </option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">
                                      Bangladesh
                                    </option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia &amp; Herzegovina">
                                      Bosnia &amp; Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">
                                      British Indian Ocean Ter
                                    </option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">
                                      Burkina Faso
                                    </option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">
                                      Canary Islands
                                    </option>
                                    <option value="Cape Verde">
                                      Cape Verde
                                    </option>
                                    <option value="Cayman Islands">
                                      Cayman Islands
                                    </option>
                                    <option value="Central African Republic">
                                      Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">
                                      Channel Islands
                                    </option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">
                                      Christmas Island
                                    </option>
                                    <option value="Cocos Island">
                                      Cocos Island
                                    </option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">
                                      Cook Islands
                                    </option>
                                    <option value="Costa Rica">
                                      Costa Rica
                                    </option>
                                    <option value="Cote DIvoire">
                                      Cote DIvoire
                                    </option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">
                                      Czech Republic
                                    </option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                      Dominican Republic
                                    </option>
                                    <option value="East Timor">
                                      East Timor
                                    </option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">
                                      El Salvador
                                    </option>
                                    <option value="Equatorial Guinea">
                                      Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">
                                      Falkland Islands
                                    </option>
                                    <option value="Faroe Islands">
                                      Faroe Islands
                                    </option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">
                                      French Guiana
                                    </option>
                                    <option value="French Polynesia">
                                      French Polynesia
                                    </option>
                                    <option value="French Southern Ter">
                                      French Southern Ter
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">
                                      Great Britain
                                    </option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">
                                      Guadeloupe
                                    </option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">
                                      Isle of Man
                                    </option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">
                                      Kazakhstan
                                    </option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">
                                      Korea North
                                    </option>
                                    <option value="Korea Sout">
                                      Korea South
                                    </option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">
                                      Kyrgyzstan
                                    </option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">
                                      Liechtenstein
                                    </option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">
                                      Luxembourg
                                    </option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">
                                      Madagascar
                                    </option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">
                                      Marshall Islands
                                    </option>
                                    <option value="Martinique">
                                      Martinique
                                    </option>
                                    <option value="Mauritania">
                                      Mauritania
                                    </option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">
                                      Midway Islands
                                    </option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">
                                      Montserrat
                                    </option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">
                                      Mozambique
                                    </option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">
                                      Netherland Antilles
                                    </option>
                                    <option value="Netherlands">
                                      Netherlands (Holland, Europe)
                                    </option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">
                                      New Caledonia
                                    </option>
                                    <option value="New Zealand">
                                      New Zealand
                                    </option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">
                                      Norfolk Island
                                    </option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">
                                      Palau Island
                                    </option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">
                                      Papua New Guinea
                                    </option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">
                                      Philippines
                                    </option>
                                    <option value="Pitcairn Island">
                                      Pitcairn Island
                                    </option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">
                                      Puerto Rico
                                    </option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">
                                      Republic of Montenegro
                                    </option>
                                    <option value="Republic of Serbia">
                                      Republic of Serbia
                                    </option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">
                                      St Barthelemy
                                    </option>
                                    <option value="St Eustatius">
                                      St Eustatius
                                    </option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">
                                      St Kitts-Nevis
                                    </option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">
                                      St Maarten
                                    </option>
                                    <option value="St Pierre &amp; Miquelon">
                                      St Pierre &amp; Miquelon
                                    </option>
                                    <option value="St Vincent &amp; Grenadines">
                                      St Vincent &amp; Grenadines
                                    </option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">
                                      Samoa American
                                    </option>
                                    <option value="San Marino">
                                      San Marino
                                    </option>
                                    <option value="Sao Tome &amp; Principe">
                                      Sao Tome &amp; Principe
                                    </option>
                                    <option value="Saudi Arabia">
                                      Saudi Arabia
                                    </option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">
                                      Seychelles
                                    </option>
                                    <option value="Sierra Leone">
                                      Sierra Leone
                                    </option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">
                                      Solomon Islands
                                    </option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">
                                      South Africa
                                    </option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">
                                      Switzerland
                                    </option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">
                                      Tajikistan
                                    </option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">
                                      Trinidad &amp; Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">
                                      Turkmenistan
                                    </option>
                                    <option value="Turks &amp; Caicos Is">
                                      Turks &amp; Caicos Is
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">
                                      United Arab Emirates
                                    </option>
                                    <option value="United States of America">
                                      United States of America
                                    </option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">
                                      Uzbekistan
                                    </option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">
                                      Vatican City State
                                    </option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">
                                      Virgin Islands (Brit)
                                    </option>
                                    <option value="Virgin Islands (USA)">
                                      Virgin Islands (USA)
                                    </option>
                                    <option value="Wake Island">
                                      Wake Island
                                    </option>
                                    <option value="Wallis &amp; Futana Is">
                                      Wallis &amp; Futana Is
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                </div>
                              )}
                              {transferOption === "International" && (
                                <div className="form-group">
                                  <label htmlFor="routingNumber">
                                    Routing Number
                                  </label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-control"
                                    id="routingNumber"
                                    required
                                    placeholder="Enter routing number"
                                    value={routingNumber}
                                    onChange={(e) =>
                                      setRoutingNumber(e.target.value)
                                    }
                                  />
                                </div>
                              )}
                              {transferOption === "International" && (
                                <div className="form-group">
                                  <label htmlFor="swiftCode">Swift Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="swiftCode"
                                    required
                                    placeholder="Enter swift code"
                                    value={swiftCode}
                                    onChange={(e) =>
                                      setSwiftCode(e.target.value)
                                    }
                                  />
                                </div>
                              )}
                              <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                  className="form-control"
                                  rows={4}
                                  id="description"
                                  required
                                  placeholder="Payment Description"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </div>

                              <p className="text-muted charge-area mt-4">
                                Transactions fees
                                <span className="float-right d-flex align-items-center">
                                  <del>1.00 USD</del>
                                  <span className="bg-success text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2 free-charge">
                                    Free
                                  </span>
                                </span>
                              </p>
                              <hr />
                              <p className="font-weight-500">
                                You'll transfer
                                <span className="text-3 float-right">
                                  {amount} USD
                                </span>
                              </p>
                              {loading ? (
                                <button
                                  className="btn btn-default btn-center btn-block"
                                  disabled
                                >
                                  <span className="bh" />
                                  <span>Processing...</span>
                                </button>
                              ) : (
                                <button className="btn btn-default btn-center btn-block">
                                  <span className="bh" />
                                  <span>Continue</span>
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {otpModalOpen && !transferCompleted && (
                  <div className="col-lg-9">
                    <h3 className="text-5 font-weight-700 d-flex admin-heading">
                      Confirm Transfer
                    </h3>
                    <div className="bg-white">
                      <div className="row">
                        <div className="col-md-8 col-lg-8 mx-auto">
                          <div className="bg-light shadow-md rounded p-3 p-sm-4 form-box">
                            <h3 className="text-5 msg-header">
                              We sent an OTP to your email address. Please input
                              the code below to confirm your transaction.
                            </h3>
                            {/* Deposit Money Confirm  */}
                            <form id="form-send-money" onSubmit={checkOtp}>
                              <div className="form-group">
                                <label htmlFor="otp">OTP</label>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  id="otp"
                                  required
                                  placeholder="Enter code here"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                              <div className="alert alert-info rounded shadow-sm py-3 px-4 px-sm-2 mb-4">
                                <div className="row">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                    Transfer Type:
                                  </p>
                                  <p className="col-sm-7">{transferOption}</p>
                                </div>
                                <div className="row">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                    Account Name:
                                  </p>
                                  <p className="col-sm-7">{accountName}</p>
                                </div>
                                <div className="row">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                    Account Number:
                                  </p>
                                  <p className="col-sm-7">{accountNumber}</p>
                                </div>
                                <div className="row">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                    Account Type:
                                  </p>
                                  <p className="col-sm-7 mb-0">{accountType}</p>
                                </div>
                                {transferOption === "International" && (
                                  <>
                                    <div className="row">
                                      <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                        Routing Number:
                                      </p>
                                      <p className="col-sm-7 mb-0">
                                        {routingNumber}
                                      </p>
                                    </div>
                                    <div className="row">
                                      <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                        Swift Code:
                                      </p>
                                      <p className="col-sm-7 mb-0">
                                        {swiftCode}
                                      </p>
                                    </div>
                                    <div className="row">
                                      <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                        Country:
                                      </p>
                                      <p className="col-sm-7 mb-0">{country}</p>
                                    </div>
                                  </>
                                )}
                                <div className="row">
                                  <p className="col-sm-5 opacity-7 text-sm-right mb-0 mb-sm-3">
                                    Description:
                                  </p>
                                  <p className="col-sm-7 mb-0">{description}</p>
                                </div>
                              </div>
                              <h3 className="text-5 font-weight-400 mb-3">
                                Details
                              </h3>
                              <p className="mb-1">
                                Transfer Amount
                                <span className="text-3 float-right">
                                  {amount} USD
                                </span>
                              </p>
                              <p className="mb-1">
                                Fees{" "}
                                <span className="text-3 float-right">
                                  0.00 USD
                                </span>
                              </p>
                              <hr />
                              <p className="font-weight-500">
                                Total
                                <span className="text-3 float-right">
                                  {amount} USD
                                </span>
                              </p>
                              {loading ? (
                                <button
                                  className="btn btn-default btn-center btn-block"
                                  disabled
                                >
                                  <span className="bh" />
                                  <span>Processing...</span>
                                </button>
                              ) : (
                                <button className="btn btn-default btn-center btn-block">
                                  <span className="bh" />
                                  <span>Continue</span>
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {transferCompleted && (
                  <div className="col-lg-9">
                    <div className="bg-white">
                      <div className="row">
                        <div className="col-md-8 col-lg-8 mx-auto">
                          <div className="bg-light shadow-md rounded p-3 p-sm-4 form-box">
                            <div className="bg-light shadow-sm rounded p-3 p-sm-4 mb-4">
                              <div className="text-center my-5">
                                <p className="text-center text-success text-20 line-height-07">
                                  <i className="fas fa-check-circle" />
                                </p>
                                <p className="text-center text-success text-8 line-height-07">
                                  Success!
                                </p>
                              </div>
                              <p className="text-center text-3 mb-4">
                                Your transfer of ${amount} is being reviewed.
                                See transaction details under
                                <Link
                                  style={{ marginLeft: "5px" }}
                                  to="/transactions"
                                >
                                  Transactions
                                </Link>
                                .
                              </p>
                              <button
                                className="btn btn-default btn-center btn-block"
                                onClick={() => {
                                  navigate(0);
                                }}
                              >
                                <span className="bh" />
                                <span>Send Money Again</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="col-lg-9">
                <div className="bg-white">
                  <div className="row">
                    <div className="col-md-8 col-lg-8 mx-auto">
                      <div className="bg-light shadow-md rounded p-3 p-sm-4 form-box">
                        <div className="bg-light shadow-sm rounded p-3 p-sm-4 mb-4">
                          <div className="text-center my-5">
                            <img
                              src="images/error.png"
                              alt="error"
                              style={{ width: "30%", margin: "auto" }}
                            />
                          </div>
                          <p className="text-center text-3 mb-4">
                            Account on hold. Please contact support.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default SendMoney;
