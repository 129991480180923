import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

const Cards = () => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div id="main-wrapper">
      <Header />
      <div id="content" className="py-4">
        <div className="container">
          <div className="row">
            <Sidebar activePage="Cards" />
            <div className="col-lg-9">
              <div className="bg-light shadow-sm rounded p-4">
                <h3 className="text-5 font-weight-400 mb-4">
                  Credit or Debit Cards
                  <span className="text-muted text-4">(for payments)</span>
                </h3>
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0">
                      <p className="text-4">XXXX-XXXX-XXXX-8943</p>
                      <p className="d-flex align-items-center">
                        <span className="account-card-expire text-uppercase d-inline-block opacity-6 mr-2">
                          Valid
                          <br />
                          thru
                          <br />
                        </span>
                        <span className="text-4 opacity-9">04/28</span>
                        <span className="bg-light text-0 text-body font-weight-500 rounded-pill d-inline-block px-2 line-height-4 opacity-8 ml-auto">
                          Primary
                        </span>
                      </p>
                      <p className="d-flex align-items-center m-0">
                        <span className="text-uppercase font-weight-500">
                          {user.firstName} {user.lastName}
                        </span>
                        <img
                          className="ml-auto"
                          src="images/visa.png"
                          alt="visa"
                          title
                        />
                      </p>
                      <div className="account-card-overlay rounded">
                        <a href="#/" className="text-light btn-link mx-2">
                          <span className="mr-1">
                            <i className="fas fa-minus-circle" />
                          </span>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className="account-card text-white rounded p-3 mb-4 mb-lg-0">
                      <p className="text-4">XXXX-XXXX-XXXX-2345</p>
                      <p className="d-flex align-items-center">
                        <span className="account-card-expire text-uppercase d-inline-block opacity-6 mr-2">
                          Valid
                          <br />
                          thru
                          <br />
                        </span>
                        <span className="text-4 opacity-9">07/23</span>
                      </p>
                      <p className="d-flex align-items-center m-0">
                        <span className="text-uppercase font-weight-500">
                          {user.firstName} {user.lastName}
                        </span>
                        <img
                          className="ml-auto"
                          src="images/mastercard.png"
                          alt="mastercard"
                          title
                        />
                      </p>
                      <div className="account-card-overlay rounded">
                        <a href="#/" className="text-light btn-link mx-2">
                          <span className="mr-1">
                            <i className="fas fa-minus-circle" />
                          </span>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <a
                      href="#/"
                      data-target="#add-new-card-details"
                      data-toggle="modal"
                      className="account-card-new d-flex align-items-center rounded h-100 p-3 mb-4 mb-lg-0"
                    >
                      <p className="w-100 text-center line-height-4 m-0">
                        <span className="text-3">
                          <i className="fas fa-plus-circle" />
                        </span>
                        <span className="d-block text-body text-3">
                          Add New Card
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cards;
