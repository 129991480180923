import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";

const Settings = () => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div id="main-wrapper">
      <Header />
      <div id="content" className="py-4">
        <div className="container">
          <div className="row">
            <Sidebar activePage="Settings" />
            <div className="col-lg-9">
              <h3 className="text-5 font-weight-700 d-flex admin-heading">
                Notifications Setting
              </h3>
              <div className="bg-white">
                {/* Notifications  */}
                <div className="bg-light shadow-sm rounded p-4 mb-4 form-bo">
                  <h3 className="text-5 font-weight-400">Notifications</h3>
                  <p className="text-muted">
                    Select subscriptions to be delivered to
                    <span className="text-body" style={{ marginLeft: "3px" }}>
                      {user.email}
                    </span>
                  </p>
                  <hr className="mx-n4" />
                  <form id="notifications">
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="announcements"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="announcements"
                      >
                        Announcements
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Be the first to know about new features and other news.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="sendPayment"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="sendPayment"
                      >
                        Send payment
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Send an email when I send a payment.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="receiveApayment"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="receiveApayment"
                      >
                        Receive a payment
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Send me an email when I receive a payment.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="requestPayment"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="requestPayment"
                      >
                        Request payment
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Send me an email when i request payment.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="problemWithPayment"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="problemWithPayment"
                      >
                        Have a problem with a payment
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Send me an email when have a problem with a payment.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="specialOffers"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="specialOffers"
                      >
                        Special Offers
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Receive last-minute offers from us.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        id="reviewSurveys"
                        name="notifications"
                        className="custom-control-input"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label text-3"
                        htmlFor="reviewSurveys"
                      >
                        Review Surveys
                      </label>
                      <p className="text-muted line-height-3 mt-2">
                        Share your payment experience to better inform users.
                      </p>
                    </div>
                    <hr className="mx-n4" />
                    <button
                      className="btn btn-default btn-center"
                      type="submit"
                    >
                      <span className="bh" />
                      <span>Save Changes</span>
                    </button>
                  </form>
                </div>
                {/* Notifications End */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Settings;
