import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://app.cathaynational.com/api/auth/forgotpassword",
        {
          email: email.toLowerCase(),
        }
      );

      if (data === "Email sent") {
        setLoading(false);
        setEmail("");
        toast.success("Email sent!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="login-page" style={{ textAlign: "center" }}>
      <div className="signup-login">
        <div
          className="sl-form-wrap"
          style={{ margin: "auto", marginTop: "60px" }}
        >
          <div className="content-box">
            <h2>Forgot Password?</h2>
            <p>
              Enter your email and we'll send you a link to reset your password.
            </p>
          </div>
          <form onSubmit={forgotPasswordHandler} className="sl-form">
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                placeholder="Email address"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            {loading ? (
              <button
                className="btn btn-filled btn-round"
                disabled
                type="button"
              >
                <span className="bh" /> <span>Sending...</span>
              </button>
            ) : (
              <button className="btn btn-filled btn-round">
                <span className="bh" /> <span>Send Email</span>
              </button>
            )}
          </form>
        </div>
      </div>

      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default ForgotPassword;
