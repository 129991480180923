import React, { useState } from "react";
import logo from "../assets/logo-dark.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const logout = () => {
    localStorage.removeItem("cathay-dashboard");
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <header className="header02">
      <div className="header-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-8">
              <div className="d-inline-flex ml-auto">
                <a
                  href="mailto:support@cathaynational.com"
                  className="top-text"
                >
                  <i className="fas fa-phone-alt" /> support@cathaynational.com
                </a>
                <a href="#/" className="top-text">
                  <i className="fas fa-envelope" /> +1 (970) 316‑4537
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-main">
        <div className="container d-flex align-items-center">
          <a
            className="logo d-inline-flex"
            href="https://www.cathaynational.com"
          >
            <img src={logo} alt="logo" />
          </a>
          <nav className="primary-menu ml-auto">
            <a id="mobile-menu-toggler" href="#/" onClick={toggleDropdown}>
              <i className="fas fa-bars" />
            </a>
            <ul>
              <li className="current-menu-item has-menu-child">
                <a href="https://www.cathaynational.com">Home</a>
              </li>
              <li className="current-menu-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="has-menu-child">
                <Link to="/send-money">Send Money</Link>
              </li>
              <li className="has-menu-child">
                <Link to="/transactions">Transactions</Link>
              </li>
              <li className="has-menu-child pro-menu-drop">
                <a href="#/" onClick={toggleProfileDropdown}>
                  {user?.picture && (
                    <div className="header-pro-thumb">
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                      />
                    </div>
                  )}
                  {user?.firstName} {user?.lastName}{" "}
                  <i className="fas fa-chevron-down" />
                </a>
                <ul
                  className="dropdown-menu-md sub-menu profile-drop"
                  style={
                    profileDropdownOpen
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li className="dropdown-header">
                    <div>
                      <h5 className="hidden-xs m-b-0 text-primary text-ellipsis">
                        {user?.firstName} {user?.lastName}
                      </h5>
                      <div className="small text-muted">
                        <span>Account ID: {user?.accountNumber}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <hr className="mx-n3 mt-0" />
                  </li>
                  <li className="nav__create-new-profile-link">
                    <Link to="/profile">
                      <span>View personal profile</span>
                    </Link>
                  </li>
                  <li className="divider" />
                  <li className="nav__dropdown-menu-items">
                    <a href="#/" onClick={logout}>
                      <i className="icon icon-logout" />
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul
              style={dropdownOpen ? { display: "block" } : { display: "none" }}
            >
              <li className="current-menu-item has-menu-child">
                <a href="https://www.cathaynational.com">Home</a>
              </li>
              <li className="current-menu-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="has-menu-child">
                <Link to="/send-money">Send Money</Link>
              </li>
              <li className="has-menu-child">
                <Link to="/transactions">Transactions</Link>
              </li>
              <li className="has-menu-child pro-menu-drop">
                <a href="#/" onClick={toggleProfileDropdown}>
                  {user?.picture && (
                    <div className="header-pro-thumb">
                      <img
                        className="rounded-circle"
                        src={user?.picture}
                        alt="avatar"
                      />
                    </div>
                  )}
                  {user?.firstName} {user?.lastName}{" "}
                  <i className="fas fa-chevron-down" />
                </a>
                <ul
                  className="dropdown-menu-md sub-menu profile-drop"
                  style={
                    profileDropdownOpen
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li className="dropdown-header">
                    <div>
                      <h5 className="hidden-xs m-b-0 text-primary text-ellipsis">
                        {user?.firstName} {user?.lastName}
                      </h5>
                      <div className="small text-muted">
                        <span>Account ID: {user?.accountNumber}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <hr className="mx-n3 mt-0" />
                  </li>
                  <li className="nav__create-new-profile-link">
                    <Link to="/profile">
                      <span>View personal profile</span>
                    </Link>
                  </li>
                  <li className="divider" />
                  <li className="nav__dropdown-menu-items">
                    <a href="#/" onClick={logout}>
                      <i className="icon icon-logout" />
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
