import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPassword = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      await axios.put(
        `https://app.cathaynational.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="login-page" style={{ textAlign: "center" }}>
      <div className="signup-login">
        <div
          className="sl-form-wrap"
          style={{ margin: "auto", marginTop: "60px" }}
        >
          <div className="content-box">
            <h2>Reset Password</h2>
            <p>Enter a new password</p>
          </div>
          <form onSubmit={resetPassword} className="sl-form">
            <div className="form-group">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your new password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                  setPasswordWeak(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                id="confirm-password"
                type="password"
                placeholder="Enter your password again"
                required
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordError(false);
                  setPasswordWeak(false);
                }}
              />
            </div>
            {passwordWeak && (
              <p className="text-danger">
                Password should at least 8 characters long
              </p>
            )}
            {passwordError && (
              <p className="text-danger">Passwords do not match</p>
            )}

            {loading ? (
              <button
                className="btn btn-filled btn-round"
                disabled
                type="button"
              >
                <span className="bh" /> <span>Confirm</span>
              </button>
            ) : (
              <button className="btn btn-filled btn-round">
                <span className="bh" /> <span>Confirm</span>
              </button>
            )}
          </form>
        </div>
      </div>

      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default ResetPassword;
