import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="foo-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget foo-nav">
                <h5>Access Us</h5>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="#/">Payment</a>
                  </li>
                  <li>
                    <Link to="/send-money">Send Money</Link>
                  </li>
                  <li>
                    <a href="#/">Fees</a>
                  </li>
                  <li>
                    <a href="#/">Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget foo-nav">
                <h5>Help Center</h5>
                <ul>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <a href="#/">Live Chat</a>
                  </li>
                  <li>
                    <a href="#/">Payment</a>
                  </li>
                  <li>
                    <a href="#/">Custom API</a>
                  </li>
                  <li>
                    <a href="#/">Affiliate</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget foo-nav">
                <h5>Partners</h5>
                <ul>
                  <li>
                    <a href="#/">Refer A Frient</a>
                  </li>
                  <li>
                    <a href="#/">Affiliate Programs</a>
                  </li>
                  <li>
                    <a href="#/">Payment API</a>
                  </li>
                  <li>
                    <a href="#/">Partner Directory</a>
                  </li>
                  <li>
                    <a href="#/">Become A Partner</a>
                  </li>
                  <li>
                    <a href="#/">Find Locations</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget foo-nav">
                <h5>Services</h5>
                <ul>
                  <li>
                    <a href="#/">Transfer Money</a>
                  </li>
                  <li>
                    <a href="#/">Saving Account</a>
                  </li>
                  <li>
                    <a href="#/">Online Shopping</a>
                  </li>
                  <li>
                    <a href="#/">Pay Bills</a>
                  </li>
                  <li>
                    <a href="#/">Credit Service</a>
                  </li>
                  <li>
                    <a href="#/">Ecommerce</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget foo-address">
                <h5>Stay In Touch</h5>
                <address>
                  <a href="mailto:support@cathaynational.com">
                    support@cathaynational.com
                  </a>
                  <br />
                  <a href="#/">+1 (970) 316‑4537</a>
                </address>
                <address>
                  705 N Mountain Rd Newington, Connecticut(CT), 06111
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="foo-btm">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="foo-navigation">
                <ul>
                  <li>
                    <a href="#/">Terms and Conditions</a>
                  </li>
                  <li>
                    <a href="#/">Privacy &amp; Policy</a>
                  </li>
                  <li>
                    <a href="#/">Legal</a>
                  </li>
                  <li>
                    <a href="#/">Notice</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="copyright">
                Copyright © {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
