import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ activePage }) => {
  return (
    <aside className="col-lg-3 sidebar">
      <div className="bg-light shadow-sm text-center mb-4">
        <div className="Profile-menu">
          <ul className="nav secondary-nav">
            <li
              className={`${
                activePage === "Dashboard" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/">
                <i className="fas fa-tachometer-alt" /> Dashboard
              </Link>
            </li>
            <li
              className={`${
                activePage === "Send money" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/send-money">
                <i className="far fa-paper-plane" /> Send Money
              </Link>
            </li>
            <li
              className={`${
                activePage === "Transactions" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/transactions">
                <i className="fas fa-list-ul" />
                Transactions
              </Link>
            </li>
            <li
              className={`${
                activePage === "Cards" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/cards">
                <i className="fas fa-university" /> Cards
              </Link>
            </li>
            <li
              className={`${
                activePage === "Profile" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/profile">
                <i className="fab fa-autoprefixer" /> Profile
              </Link>
            </li>
            <li
              className={`${
                activePage === "Settings" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link className="nav-link" to="/settings">
                <i className="fas fa-cog" />
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="bg-light shadow-sm text-center p-3 mb-4 side-block">
        <div className="text-17 text-light my-3">
          <i className="fas fa-coins" />
        </div>
        <h2 className="tex9-3 font-weight-700 my-4">Earn $50</h2>

        <a href="#/" className="btn btn-default btn-center">
          <span className="bh" />
          <span>Refer A friend</span>
        </a>
      </div>

      <div className="bg-light shadow-sm text-center p-3 mb-4 side-block">
        <div className="text-17 text-light my-3">
          <i className="fas fa-comments" />
        </div>
        <h2 className="text-9 font-weight-407 my-4">Need Help?</h2>
        <p className="opacity-8 mb-4">
          Have questions or concerns regarding your account?
          <br />
          Our experts are here to help.
        </p>
        <a
          href="https://www.cathaynational.com/contact"
          className="btn btn-default btn-center"
        >
          <span className="bh" />
          <span>Start Chat</span>
        </a>
      </div> */}
    </aside>
  );
};

export default Sidebar;
