import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  noUser,
  loadUser,
  logoutUser,
} from "./store/redux/userRedux";
import axios from "axios";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SendMoney from "./pages/SendMoney";
import Cards from "./pages/Cards";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import Transactions from "./pages/Transactions";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("cathay-dashboard");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://app.cathaynational.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("cathay-dashboard");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={user ? <Dashboard /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/cards"
        element={user ? <Cards /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <ForgotPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/passwordreset/:resetToken"
        element={!user ? <ResetPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/send-money"
        element={user ? <SendMoney /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/settings"
        element={user ? <Settings /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/"} />}
      />
    </Routes>
  );
};

export default App;
