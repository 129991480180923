import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../store/redux/userRedux";

const Login = () => {
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (type === "email") {
        const { data } = await axios.post(
          "https://app.cathaynational.com/api/auth/login",
          {
            email: input?.toLowerCase(),
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("cathay-dashboard", data.token);
        navigate("/");
      } else {
        const { data } = await axios.post(
          "https://app.cathaynational.com/api/auth/login",
          {
            accountNumber: input,
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("cathay-dashboard", data.token);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account disabled") {
          toast.error("Account disabled. Please contact support.");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="login-page" style={{ textAlign: "center" }}>
      <div className="signup-login">
        <div
          className="sl-form-wrap"
          style={{ margin: "auto", marginTop: "60px" }}
        >
          <div className="content-box">
            <h2>Cathay National Dashboard</h2>
            <p>Login to your Cathay National account dashboard</p>
          </div>
          <form onSubmit={loginHandler} className="sl-form">
            <div className="form-group">
              <label htmlFor="email-account">Email or Account number</label>
              <input
                type="text"
                id="email-account"
                placeholder="Email or Account number"
                required
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  const value = e.target.value;
                  if (!isNaN(+value)) {
                    setType("accountNumber");
                  } else {
                    setType("email");
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="form-check">
              <Link to="/forgot-password" className="form-check-label">
                Forgot Password?
              </Link>
            </div>
            {loading ? (
              <button
                className="btn btn-filled btn-round"
                disabled
                type="button"
              >
                <span className="bh" /> <span>Login</span>
              </button>
            ) : (
              <button className="btn btn-filled btn-round">
                <span className="bh" /> <span>Login</span>
              </button>
            )}
          </form>
        </div>
      </div>

      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default Login;
