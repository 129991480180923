import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { logoutUser } from "../store/redux/userRedux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("cathay-dashboard");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://app.cathaynational.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
        setOccupation(data.occupation);
        setTimeout(() => {
          setPageLoading(false);
        }, 800);
      } catch (error) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("cathay-dashboard");
            dispatch(logoutUser());
          } else {
            localStorage.removeItem("cathay-dashboard");
            dispatch(logoutUser());
          }
        }
      }
    };

    getUser();
  }, [dispatch]);

  const editUser = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("cathay-dashboard");

    try {
      setLoading(true);

      await axios.put(
        `https://app.cathaynational.com/api/user/${user?._id}`,
        {
          firstName,
          middleName,
          lastName,
          email,
          phone,
          occupation,
          address,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Changes saved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div id="main-wrapper">
      <Header />
      <div id="content" className="py-4">
        <div className="container">
          <div className="row">
            <Sidebar activePage="Profile" />
            <div className="col-lg-9">
              <div className="bg-light shadow-sm rounded infoItems">
                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                    Account ID:
                  </p>
                  <p className="col-sm-9">{user.accountNumber}</p>
                </div>

                <div
                  id="edit-personal-details"
                  className="accord"
                  style={{ display: "block" }}
                >
                  <div>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title font-weight-400">Profile</h5>
                      </div>
                      <div className="modal-body p-4">
                        <form id="personaldetails" onSubmit={editUser}>
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="firstName">
                                  First Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="firstName"
                                  value={firstName}
                                  required
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="middleName">Middle Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="middleName"
                                  value={middleName}
                                  onChange={(e) => {
                                    setMiddleName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="lastName">
                                  Last Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="lastName"
                                  value={lastName}
                                  required
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="email">
                                  Email <span>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  value={email}
                                  required
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="phone">
                                  Phone <span>*</span>
                                </label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="phone"
                                  value={phone}
                                  required
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="occupation">Occupation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="occupation"
                                  value={occupation}
                                  onChange={(e) => {
                                    setOccupation(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {changesMade && (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary btn-block mt-2"
                                  type="button"
                                  disabled
                                >
                                  Saving...
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-block mt-2"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              )}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {pageLoading && (
        <Loading loading background="#ffffff" loaderColor="#123d88" />
      )}
      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default Profile;
